$primary-color: #e51a23;
$primary-light: var(--app-background, #fff);
$lightened-background: var(--lightened-background, #f6f6f6);
$success-color: #4bb543;
$text-color: var(--text-color, #454f63);
$primary-text-color: var(--primary-text-color, #666a73);
$accent-text-color: var(--accent-text-color, #7f807fea);
$sub-text-color: #80454f63;
$text-header: var(--text-header, #000000d1);
$content-text-color: var(--text-header, #000000d1);
$text-explanatory-color: #666;
$error-color: #f00;
$tag-color: var(--tag-color, #686868);
$border-color: var(--border-color, #eeebeb);
$button-hover-color: rgb(160, 18, 24);
$disabled-button-color: var(--disabled-button-color, #0000001f);
$disabled-button-text: var(--disabled-button-text, #00000042);
$avatar-background: #c2cbdd;
$icon-color: var(--icon-color, #65676b);
$primary-dark: #15202b;
$box-shadow: 0 3px 6px var(--box-shadow-color, #00000029);
$light-box-shadow: 0 3px 6px #00000029; // rgba(136, 153, 166, 0.2) 0 0 15px 0, rgba(136, 153, 166, 0.15) 0 0 3px 1px;
$dark-box-shadow: 0 3px 6px #00000029; // rgba(101, 119, 134, 0.2) 0 0 15px 0, rgba(101, 119, 134, 0.15) 0 0 3px 1px;
$dark-field: #253341;
$dark-card: #192734;
$dark-border: #38444d;
$dark-manifest: #121b2d;
$dark-hashtag: #2e89ff;
$light-hashtag: #1876f2;
$visited-link: #c75bc7;
$text-alt-color: var(--text-alt-color, #000000cb);
$theme-light: #fff;
