@import '../../../../styles/variables';
@import '../../../../styles/mediaQuery';

.drawerWrapper {
 min-width: 15rem;

  .drawerBrand {
    cursor: pointer;
    display: grid;
    gap: 0.5em;
    justify-items: center;
    margin-bottom: 1em;

    .ictlife {
      font-weight: 600;
    }
  }

  .drawerHolder {
    .link {
      padding: 1em 0;

      &.tag {
        align-items: center;
        display: grid;
        gap: 0.5em;
        grid-template-columns: repeat(2, max-content);

        .icon {
          font-size: 1.2em;
        }
      }

      a {
        text-decoration: none;
      }
    }
  }

  .sectionAvatar {
    height: 5em;
    width: 5em;
  }
}

.goLive {
  display: grid;
  justify-items: center;
}

.businessSummary {
  border-radius: 7px;
  display: grid;
  grid-auto-flow: column;

  .summary {
    cursor: pointer;
    display: grid;
    font-size: 0.9em;
    justify-items: center;
    padding: 10px 20px;

    &:last-child {
      cursor: unset;
    }

    .count {
      font-weight: 600;
    }
  }
}
