@import '../../../styles/variables';
@import '../../../styles/mediaQuery';

.sectionContent {
  h1 {
    color: $text-header;
    font-size: 1.8em;
    font-weight: 600;
  }

  p {
    line-height: 1.7em;
  }

  .aboutUsHolder {
    display: grid;
    gap: 8.1em;
    justify-items: center;
    padding: 4.1em 0;

    @include phone {
      padding: 2em 0;
    }

    .subSection {
      color: $primary-text-color;
      display: grid;
      gap: 2.8em;
      margin: auto;
      width: 88.7%;

      p {
        font-size: 1.15em;
        text-align: left;
      }

      h4 {
        color: #c4c5d0;
        font-weight: 600;
      }

      img {
        width: 60%;

        @include phone {
          width: 100%;
        }
      }
    }

    .centeredSection {
      text-align: center;

      .centeredParagraph {
        text-align: center;
      }
    }
  }

  .termsHolder {
    display: grid;
    grid-template-columns: 23% 77%;
    margin-bottom: 3em;

    @include phone {
      grid-template-columns: 1fr;
    }

    .subSection {
      color: $primary-text-color;
      display: grid;
      gap: 2.8em;
      width: 100%;

      h4 {
        color: #c4c5d0;
        font-weight: 600;
      }

      img {
        width: 60%;

        @include phone {
          width: 100%;
        }
      }

      p {
        font-weight: 550;
        line-height: 2em;
      }
    }

    h1 {
      color: $primary-color;
      font-size: 1.4em;
      font-weight: 550;
    }

    .navigation {
      position: relative;

      @include phone {
        display: none;
      }

      .navHolder {
        border-right: 1px solid var(--box-shadow-color, #00000029);
        overflow-y: auto;
        padding: 5.3em 1.67em;

        .link {
          color: $text-header;
          cursor: pointer;
          font-size: 0.96em;
          margin-bottom: 1.45em;

          &:hover {
            color: $primary-color;
          }

          a {
            color: unset;
            display: inherit;
            text-decoration: none;

            &.active {
              color: $primary-color;
            }
          }
        }
      }
    }

    .termsContent {
      padding: 0 3.15em;

      @include phone {
        padding: 0 1em;
      }

      .subSection:not(:first-of-type) {
        padding-top: 2em;
      }

      h3 {
        font-weight: 600;
      }

      .subSection:first-of-type {
        padding-top: 3.45em;
      }
    }
  }

  .list {
    display: grid;
    gap: 1em;
    line-height: 2em;

    .listItem {
      align-items: center;
      display: grid;
      gap: 1em;
      grid-template-columns: max-content auto;

      .number {
        align-items: center;
        background: $primary-light 0% 0% no-repeat padding-box;
        border-radius: 11px;
        box-shadow: $box-shadow;
        color: $primary-color;
        display: grid;
        font-weight: 600;
        height: 30px;
        justify-items: center;
        left: 111px;
        width: 30px;
      }

      .bullet {
        display: grid;
        height: 100%;
        justify-items: center;
        padding: 0.25em 0;

        img {
          height: 1.8em;
          width: auto;
        }
      }
    }
  }

  .romanList {
    display: grid;
    gap: 0.5em;
    margin-top: 0.6em;
  }

  .gridList {
    display: grid;
    gap: 4em;

    &.twoColumns {
      grid-template-columns: repeat(auto-fill, 45%);

      @include phone {
        grid-template-columns: unset;
      }
    }

    .gridListItem {
      line-height: 2em;

      .listItemHeader {
        align-items: center;
        color: $text-header;
        display: grid;
        font-weight: 600;
        gap: 1em;
        grid-template-columns: max-content auto;
        margin-bottom: 1em;

        .bullet {
          display: grid;
          height: 100%;
          justify-items: center;
          padding: 0.25em 0;

          img {
            height: 1.8em;
            width: auto;
          }
        }

        .title {
          font-size: 0.9em;
        }
      }

      .listItemDescription {
        display: grid;
        font-size: 0.9em;
        gap: 2em;
        line-height: 2em;
      }
    }
  }
}

.backgroundTitle {
  align-items: center;
  color: $theme-light;
  display: flex;
  font-weight: normal;
  height: 8em;
  justify-content: center;
  text-align: center;
  width: 100%;

  h1 {
    font-size: 1.77em;
    font-weight: 600;
  }
}

.footer {
  background-color: $primary-light;
  border-top: 1px solid $border-color;
  color: $primary-text-color;
  height: 20.5em;
  padding: 2em 5% 7%;
  position: absolute;
  width: 100%;

  .footerHolder {
    display: grid;
    grid-template-columns: repeat(3, auto);
    padding-bottom: 1.5em;

    @include phone {
      gap: 1.5em;
      grid-template-columns: unset;
    }

    .logo {
      display: grid;
      gap: 1em;

      @include phone {
        justify-items: center;
      }

      .ictlifeFullLogo {
        height: 7.8em;
        margin: 2em 0 0 7.5em;

        @include phone {
          height: 6em;
          margin: 0;
        }
      }

      .copyright {
        margin: 4em 0 0 5.8em;

        @include phone {
          margin: 0;
        }
      }
    }

    .links {
      display: grid;
      gap: 0.5em;
      margin-top: 0.7em;

      a {
        color: $primary-text-color;
        text-decoration: none;

        &:hover {
          color: $primary-color;
        }
      }
    }
  }
}
