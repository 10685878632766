.brandLayoutWrapper {
  display: grid;
  grid-template-rows: 5.1em auto;
  height: 100%;

  .contentSection {
    overflow: auto;
    position: relative;
  }
}
